import * as React from "react"
import {liveHolder} from './Pages.module.scss'
import Seo from '../components/utilities/Seo'


const LivePage = () => {
  return (
    <article className={liveHolder}>

      <h4>Coming soon</h4>
      <Seo 
        title="Merch"
        description="Kyla La Grange merch"
      />
    </article>
  )
}

export default LivePage